<template>
	<div></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'search-view',

	data() {
		return {}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
</style>
