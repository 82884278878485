<template>
	<screen class="institution-list-view" size="large">
		<h1 slot="title">Your Cause</h1>
		<div
			class="roundup-row active-institution"
			style="align-items: center;"
			v-if="activeOrg"
		>
			<div
				class="roundup-row"
				style="flex: 4; justify-content: flex-start"
			>
				<div
					class="inst-logo"
					v-if="activeOrg.logo_url"
					:style="{
						backgroundImage: 'url(' + activeOrg.logo_url + ')'
					}"
				></div>
				<v-icon v-else class="inst-icon">
					fas fa-hand-holding-heart
				</v-icon>
				<div>
					<p class="subheader">You're donating to</p>
					<p class="header">{{ activeOrg.name }}</p>
				</div>
			</div>

			<RoundupButton
				:loading="pauseLoading"
				class="clear-white"
				@click="onPauseDonations"
				style="flex: 1;
					border: 1px solid white;
					margin-top: 0px;
					color: white;
					border-radius: 4px !important;
					max-width: 172px !important;"
			>
				Pause Donations</RoundupButton
			>
		</div>
		<div
			class="roundup-row"
			style="justify-content: space-between !important; margin: 40px 0 0 0 !important; padding: 0 !important;"
		>
			<h2>Selected Nonprofits</h2>
			<RoundupButton v-if="hasOrgs" @click="onOpenSearch" class="clear"
				>Add Nonprofit</RoundupButton
			>
		</div>
		<div class="institution-table">
			<el-table
				slot="table"
				empty-text="No nonprofits selected"
				:data="institutionList.list"
				style="width: 100%"
				@row-click="showInstitutionDetail"
				:default-sort="{
					prop: 'institution.name',
					order: 'ascending'
				}"
			>
				<el-table-column sortable prop="institution.name" label="Name">
				</el-table-column>
				<el-table-column
					prop="is_active"
					label="Status"
					width="100"
					:filters="[
						{ text: 'Active', value: true },
						{ text: 'Inactive', value: false }
					]"
					:filter-method="
						(value, row) => filterTag(value, row, 'is_active')
					"
					filter-placement="bottom-end"
				>
					<template slot-scope="scope">
						<el-tag
							:type="scope.row.is_active ? 'success' : 'primary'"
							disable-transitions
							>{{
								scope.row.is_active ? 'Active' : 'Inactive'
							}}</el-tag
						>
					</template>
				</el-table-column>
				<el-table-column
					prop="institution"
					label="License"
					:formatter="
						(row, column, cellValue, index) =>
							formatLicenseText(cellValue)
					"
				>
				</el-table-column>
				<el-table-column prop="created_at" label="Date Added">
					<template slot-scope="scope">
						{{ simpleDateFormat(new Date(scope.row.created_at)) }}
					</template>
				</el-table-column>
				<el-table-column label="Total Donations"
					><template slot-scope="scope"
						>${{
							scope.row.transactions.length
								? scope.row.transactions[0].total.toFixed(2)
								: '0.00'
						}}</template
					>
				</el-table-column>
				<el-table-column label=" ">
					<template slot-scope="scope">
						<el-button
							v-if="!scope.row.is_active"
							size="mini"
							:disabled="isSaving"
							style="margin-right: 10px"
							@click.stop="
								activateInstitution(scope.row.institution)
							"
							>Activate</el-button
						>
						<div
							v-else
							style="
								width: 75px;
								margin-right: 10px;
								display: inline-block;
							"
						/>
						<a
							v-if="
								!scope.row.is_active &&
									!scope.row.transactions.length
							"
							@click.stop="
								onRemoveInstitution(scope.row.institution)
							"
							>Remove</a
						>
						<ui-loader :loading="isSaving" />
					</template>
				</el-table-column>
			</el-table>
			<!-- <el-pagination
				@current-change="pageChange"
				:current-page="page"
				:page-size="perPage"
				layout="prev, pager, next, total"
				:total="institutionList.list.length"
			>
			</el-pagination> -->
		</div>
		<div v-if="institutionList.rejected.length">
			<p style="margin-top: 25px">Declined Nonprofits</p>
			<div class="institution-table">
				<el-table
					slot="table"
					:data="institutionList.rejected"
					style="width: 100%"
					@row-click="showInstitutionDetail"
				>
					<el-table-column prop="institution.name" label="Name">
					</el-table-column>
					<el-table-column
						prop="is_active"
						label="Status"
						width="100"
					>
						<template slot-scope="scope">
							<el-tag
								:type="
									scope.row.is_active ? 'success' : 'primary'
								"
								disable-transitions
								>{{
									scope.row.is_active ? 'Active' : 'Inactive'
								}}</el-tag
							>
						</template>
					</el-table-column>
					<el-table-column prop="consented_at" label="Declined On">
						<template slot-scope="scope">
							{{
								simpleDateFormat(new Date(scope.row.created_at))
							}}
						</template>
					</el-table-column>
					<el-table-column label="Total Donations"
						><template slot-scope="scope"
							>${{
								scope.row.transactions.length
									? scope.row.transactions[0].total.toFixed(2)
									: '0.00'
							}}</template
						>
					</el-table-column>
					<el-table-column label=" ">
						<!-- <template slot-scope="scope">
							<a
								v-if="scope.row.transactions.length"
								@click.stop="
									removeInstitution(scope.row.institution)
								"
								>Remove</a
							>
							<ui-loader :loading="isSaving" />
						</template> -->
					</el-table-column>
				</el-table>
				<!-- <el-pagination
					@current-change="pageChange"
					:current-page="page"
					:page-size="perPage"
					layout="prev, pager, next, total"
					:total="institutionList.rejected.length"
				>
				</el-pagination> -->
			</div>
		</div>
		<div class="roundup-row" style="width: 100%; justify-content: center">
			<RoundupButton v-if="!hasOrgs" @click="onOpenSearch" class="primary"
				>Find a Nonprofit</RoundupButton
			>
		</div>
		<modal v-model="showDetailModal" size="flex">
			<InstitutionView
				style="padding: 0px; margin: 0px; height: 100%; overflow: auto"
				:value="detailRow.institution"
				:isDetailView="true"
				:is_active="detailRow.is_active"
				:is_rejected="
					!['Pending', 'Consented'].includes(
						detailRow.institution_consent
					)
				"
			/>
		</modal>
		<modal v-model="showPauseDonations" size="flex">
			<div class="pause-modal">
				<h3>Pause Donations Confirmation</h3>
				<p>
					This will deactivate all of your selected nonprofits, and
					the RoundUp at Checkout embed will stop displaying on your
					checkout page.
				</p>
				<p>Do you want to continue?</p>
				<div class="roundup-row info">
					<img
						src="@/assets/purpleInfo.png"
						style="height: 25px; width: 25px; margin-right: 10px"
					/>
					Want to give to a different cause? Simply select “Activate”
					for a nonprofit in your Selected Nonprofits list, or use the
					Search to find a new cause to give to.
				</div>
				<div class="roundup-row" style="justify-content: flex-start">
					<ui-button @click="onPauseConfirm">Confirm</ui-button>
					<ui-button
						@click="closeModal"
						type="border"
						style="margin-left: 10px"
						>Back</ui-button
					>
				</div>
			</div>
		</modal>
		<modal v-model="showRemoveModal" size="flex">
			<div class="pause-modal">
				<h3>Confirm Remove Nonprofit</h3>
				<p>
					Are you sure you want to remove this nonprofit from your
					list?
				</p>
				<p>You can always add them back later.</p>
				<div class="roundup-row" style="justify-content: flex-start">
					<ui-button @click="onConfirmRemove">Confirm</ui-button>
					<ui-button
						@click="closeModal"
						type="border"
						style="margin-left: 10px"
						>Back</ui-button
					>
				</div>
			</div>
		</modal>
	</screen>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { get, isEmpty, simpleDateFormat } from '@/utilities'
import table from '@/mixins/table'

import UiButton from '../../ui/Button'
import UiLoader from '../../ui/Loader'
import UiToggle from '../../ui/Toggle'
import Screen from '../../ui/Screen'
import Modal from '../../ui/Modal'
import InstitutionView from './InstitutionView'
import InstitutionSearch from './InstitutionSearchView'

// TODO finish edit + resend invite + developers

export default {
	name: 'institution-list-view',
	components: {
		UiButton,
		Screen,
		Modal,
		UiLoader,
		UiToggle,
		InstitutionView,
		InstitutionSearch
	},
	mixins: [table],
	computed: {
		...mapState({
			items: ({ userList }) => userList.items,
			total: ({ userList }) => userList.total
		}),
		...mapGetters(['role', 'me', 'institutionList']),
		activeOrg() {
			return (
				this.institutionList.active[0] &&
				this.institutionList.active[0].institution
			)
		},
		hasOrgs() {
			return this.institutionList.list.length > 0
		},
		switchList() {
			if (this.activeOrg) {
				return this.institutionList.list.filter(
					inst => inst.id !== this.activeOrg.id
				)
			}
			return []
		}
	},
	data() {
		return {
			showUser: false,
			error: null,
			isSaving: false,
			success: null,
			inviteCode: null,
			detailRow: { institution: null },
			pauseLoading: false,
			showDetailModal: false,
			showPauseDonations: false,
			showRemoveModal: false,
			removeInst: null,
			showRemoveModal: false
		}
	},
	methods: {
		...mapActions([
			'listMyInstitutions',
			'pauseDonations',
			'attachInstitution',
			'pauseDonations',
			'removeInstitution'
		]),
		onOpenSearch() {
			this.$router.push({ name: 'search' })
		},
		navigateToDonorDetail(donor) {
			this.$router.push({
				name: 'donors.detail',
				params: { donorId: donor.id }
			})
		},
		paramsChanged(params) {
			this.getUsers(params)
		},
		addUser() {
			this.$store.commit('USER_DETAIL', { id: null })
			this.showUser = true
		},
		navigateToUserDetail(user) {
			this.$store.commit('USER_DETAIL', user)
			this.showUser = true
		},
		filterTag(value, row, prop) {
			return row[prop] === value
		},
		activateInstitution(inst) {
			this.attachInstitution(inst.id)
				.then(() => {
					this.$swal({
						toast: true,
						position: 'top-end',
						timer: 3000,
						showConfirmButton: false,
						title: 'Success',
						text: `You are now donating to ${inst.name}`
					})
				})
				.catch(error => {
					this.$swal('Error', error.response.data.message)
				})
		},
		onRemoveInstitution(inst) {
			this.removeInst = inst
			if (this.activeOrg && inst.id === this.activeOrg.id) {
				this.showRemoveModal = true
				return
			}
			this.showRemoveModal = true
		},
		onConfirmRemove() {
			this.removeInstitution(this.removeInst.id).then(() => {
				this.$swal({
					toast: true,
					position: 'top-end',
					timer: 3000,
					showConfirmButton: false,
					title: 'Success',
					text: `You removed ${this.removeInst.name}`
				})
			})
			this.closeModal()
		},
		showInstitutionDetail(row) {
			this.detailRow = row
			const inst = row.institution
			this.$router.push({
				name: 'search.details',
				props: { uuid: inst.uuid },
				params: { uuid: inst.uuid, inst }
			})
		},
		onPauseDonations() {
			this.showPauseDonations = true
		},
		onPauseConfirm() {
			this.pauseDonations()
			this.closeModal()
		},
		closeModal() {
			var evObj = document.createEvent('Events')
			evObj.initEvent('click', true, false)
			document.getElementById('modal-close').dispatchEvent(evObj)
		},
		formatLicenseText(inst) {
			return inst && inst.unclaimed_profile === 0
				? 'Logo, cover photo, and impact statements'
				: 'Name only'
		},
		get,
		isEmpty,
		simpleDateFormat
	},

	mounted() {
		this.listMyInstitutions()
	}
}
</script>

<style lang="scss" scoped>
@import '@roundupapp/component-library/src/styles/index';
@import '../../../styles/grid';

.institution-list-view {
	color: $roundup-charcoal;
	min-height: 95vh;
	h2 {
		margin: 0;
		padding: 0;
		color: $roundup-charcoal;
		font-size: 1.25rem;
		font-weight: 400;
	}

	.el-pagination {
		margin-top: 10px;
	}
	a {
		color: $roundup-primary;
		text-decoration: underline;
	}
	.search-container {
		position: absolute;
		width: 100%;
		height: 75px;
	}
	.no-active-institution {
		margin: 15px 25px;
		margin-top: 100px;
		margin-bottom: 30px;
	}
	.active-institution {
		margin-top: 40px;
		margin-bottom: 40px;
		padding: 20px;
		height: 90px;
		padding-bottom: 25px;
		border-radius: 10px;
		text-align: left;
		background-image: linear-gradient(
			$roundup-bluegrad1,
			$roundup-bluegrad2
		);
		color: #fff;
		.inst-logo {
			height: 50px;
			width: 50px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			border-radius: 10px;
		}
		.inst-icon {
			font-size: 45px;
			border-radius: 5px;
			color: white;
		}
		p {
			margin: 0px;
			margin-left: 10px;
			&.header {
				color: white;
				font-size: 18px;
			}
			&.subheader {
				font-size: 16px;
				color: rgba(255, 255, 255, 0.75);
			}
		}
	}
	.pause-modal {
		width: 500px;
		color: $roundup-charcoal;
		h3 {
			font-size: 20px;
		}
		p {
			margin-left: 0px;
			font-size: 16px;
		}
		.roundup-row {
			&.info {
				background-color: #e9edfa !important;
				color: #4337a7;
				font-size: 16px;
				border-radius: 5px;
				padding: 10px 20px;
			}
		}
	}
}

.institution-list-view::v-deep .institution-table {
	margin: 20px 0px;
	.el-table {
		.cell {
			word-break: inherit;
		}
	}
	.el-table__header-wrapper {
		position: relative;

		tr,
		th {
			background: $roundup-lightest-grey;
			text-transform: uppercase;
			.cell {
				word-break: inherit;
				font-size: 12px;
			}
		}
		th {
			.cell {
				font-weight: 600;
			}
		}
	}
	.el-table--enable-row-hover .el-table__body {
		tr {
			td {
				transition: none;
				cursor: pointer;
			}
			&:hover {
				border-radius: 5px;
				overflow: hidden;
				td:first-child {
					border-top-left-radius: 10px;
				}
				td:last-child {
					border-top-right-radius: 10px;
				}
				td:first-child {
					border-bottom-left-radius: 10px;
				}
				td:last-child {
					border-bottom-right-radius: 10px;
				}
				> td {
					background: #f4f7fd;
					color: $roundup-charcoal;
				}
			}
		}
	}
}

.institution-list-view::v-deep .screen-sub,
.institution-list-view::v-deep .screen-body {
	display: none !important;
	margin: 0;
}
</style>
